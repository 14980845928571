import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import router from "./routes";
import vuetify from './plugins/vuetify'; 
import VueClipboard from 'vue-clipboard2'
import { store } from "./store";
import VueScrollTo from 'vue-scrollto';  
import firebase from "firebase/app";
import 'firebase/firestore';

const instance = axios.create({
	baseURL: process.env.NODE_ENV === 'production' ?
   'https://us-central1-merck-daenamu.cloudfunctions.net/event' :
   'http://localhost:5001/merck-daenamu/us-central1/event',
  timeout: 5000,
})

const firebaseConfig = {
  apiKey: "AIzaSyBmbnKSnai5LMwpURP31aqonBMEjI1VU7Q",
  authDomain: "merck-daenamu.firebaseapp.com",
  projectId: "merck-daenamu",
  storageBucket: "merck-daenamu.appspot.com",
  messagingSenderId: "354096368430",
  appId: "1:354096368430:web:efbc2fcee5630b687ec8bf"
};
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
if (location.hostname === "localhost") {
  db.useEmulator("localhost", 8082);
}
// firebase.auth().onAuthStateChanged(user => {
//   console.log('firebase');
//   store.dispatch('fetchUser', user)
// });

Vue.prototype.$http = instance

Vue.use(VueClipboard)
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease"
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
