<template> 
  <footer class="footer">
    <div class="wrapper">  
      <div class="title">꼭 알아두세요!</div>
      <ul class="notices">
        <li class="notices__list">
          <p><icon name="open-check" class="icon"/>기간</p>
          <ul class="list"> 
            <li><span class="vdn">2021</span>년 <span class="vdn">12</span>월 <span class="vdn">1</span>일 ~ <span class="vdn">12</span>월 <span class="vdn">31</span>일</li>
          </ul> 
        </li>
        <li class="notices__list">
          <p><icon name="open-check" class="icon"/>대상</p>
          <ul class="list">
            <li>연구실에서 근무하는 학생이라면 누구나</li>
          </ul> 
        </li>
        <li class="notices__list">
          <p><icon name="open-check" class="icon"/>경품 안내</p>
          <ul class="list"> 
            <li>경품 : 갤럭시 워치<span class="vdn">4 44mm</span>(색상 랜덤), 갤럭시 버즈 프로 (색상 랜덤), 정관장 에브리타임 (<span class="vdn">10mlx20</span>포), <span class="vdn">BBQ</span> 황금올리브 기프티콘, 올리브영 모바일 금액권, 편의점 모바일 금액권, 간식박스, 스타벅스 기프티콘</li>
            <li>경품은 상황에 따라 안내된 이미지와 다른 유사한 제품으로 변경될 수 있습니다.</li>
            <li>당첨 고지 : 입력하신 휴대폰 번호로 이벤트 종료 후 개별 안내가 이루어집니다.</li>
            <li>당첨자 개별 안내 후 <span class="vdn">2022</span>년 <span class="vdn">1</span>월 <span class="vdn">10</span>일 내 지급</li>
          </ul> 
        </li>
        <li class="notices__list">
          <p><icon name="open-check" class="icon"/>주의 사항</p>
          <ul class="list"> 
            <li>제출해주신 사연은 홍보를 위해 활용될 수 있습니다. (익명 유지)</li>
            <li>경품 변경 및 수신 휴대폰 번호 변경은 불가능하니, 수신하실 휴대폰 번호를 신중히 입력해주시기 바랍니다.</li>
            <li>기타 문의사항은 <span class="vdn">rnadigital@merckgroup.com</span>으로 문의 바랍니다.</li>
            <li>해당 이벤트는 당사 사정에 따라 사전 고지 없이 조기 종료 또는 변경될 수 있습니다.</li>
            <li>부적절(비방, 욕설, 희롱 등)한 대나무숲 작성글은 관리자에 의해 임의로 삭제 처리 될 수 있습니다.</li>
            <li>부적절한 방법으로 참여한 내용이 발견될 경우, 경품 당첨에서 제외될 수 있습니다. (예. 매크로 등 기타 반복 행위)</li>
          </ul> 
        </li>
      </ul>
    </div> 
  </footer>  
</template>
<script> 
import Icon from "@/components/Icon";

export default {
  components: { 
    Icon,
  }, 
};
</script>
<style lang="scss">  
.footer {
  position: relative;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto; 
  background: $red;
  .wrapper {
    display: flex;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .title {
    margin-right: 120px;
    flex-shrink: 0;
    font-weight: 700;
    font-size: 20px;
    color: $white;
    letter-spacing: -0.05em;
  }
  .notices {
    width: 100%;
    max-width: 750px;
    &__list {
      color: $white;
      p {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        letter-spacing: -0.05em;
        font-weight: 700;
        color: $yellow;
        .icon {
          margin-right: 8px;
          width: 16px;
          stroke: $yellow; 
        }
      }
      .list {
        li { 
          position: relative;
          padding-left: 0.800em; 
          font-size: 14px;
          line-height: 1.6;
          letter-spacing: -0.05em;
          &::before {
            content: '';
            position: absolute;
            top: 0.7em;
            left: 0.06em;
            width: 2px;
            height: 2px;
            background: #ffffff; 
            border-radius: 99px;
          }
        } 
      }
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }  
  @media (max-width: 800px) {
    .wrapper { 
      display: block;
      padding-top: 60px;
      padding-bottom: 60px;
    }
    .title {
      margin-right: 0;  
      margin-bottom: 1.538em;
      font-size: 26px; 
    }
    .notices { 
      &__list { 
        p { 
          margin-bottom: 0.833em; 
          font-size: 24px;
          .icon {
            margin-right: 0.500em;
            width: 1.000em; 
          }
        }
        .list {
          li {   
            font-size: 22px; 
            &::before { 
              width: 0.182em;
              height: 0.182em; 
            }
          } 
        }
        &:not(:last-child) {
          margin-bottom: 50px;
        }
      }
    }   
  }
  @media (max-width: 700px) {
    .wrapper {  
      padding-top: 8.571vw;
      padding-bottom: 8.571vw;
    }
    .title {
      font-size: 3.714vw;
    }
    .notices {
      &__list { 
        p {  
          font-size: 3.428vw; 
        }
        .list {
          li {   
            font-size: 3.142vw;  
          } 
        }
        &:not(:last-child) {
          margin-bottom: 7.142vw;
        }
      }

    }

  }
}
</style>