<template>
  <header class="header">
    <div class="wrapper">
      <h1 class="logo-merck">
        <logo class="logo"/> 
      </h1>
      <ul class="sns">
        <li>
          <a href="https://pf.kakao.com/_JQfwj?utm_source=kr&utm_medium=social&utm_campaign=kakao" target="_blank" rel="noopener noreferrer" ><icon name="kakao" class="icon"
          /></a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UC1q6842hLnsMG_0JdHs4KWw/featured" target="_blank" rel="noopener noreferrer"><icon name="youtube" class="icon"
          /></a>
        </li>
        <li><a href="https://blog.naver.com/merckrnakorea" target="_blank" rel="noopener noreferrer"><icon name="blog" class="icon"
          /></a></li> 
      </ul>
    </div>
  </header>
</template> 

<script>
import Logo from "@/components/Logo";
import Icon from "@/components/Icon";

export default {
  components: {
    Logo,
    Icon,
  }, 
};
</script>


<style lang="scss">
.header {
  position: relative;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto; 
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .logo-merck {
    .logo {
      width: 148px;
      fill: currentColor;
      color: $darkgreen;
    } 
  }
  ul.sns {
    display: flex;
    align-items: center;
    li {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 999px;
        background: $red;
      }
      .icon {
        width: 20px;
        fill: currentColor;
        color: $white;
      }
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
  @media (max-width: 800px) {
    .wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .logo-merck {
      .logo {
        width: 148px; 
      } 
    }
    ul.sns { 
      li {
        a { 
          width: 60px;
          height: 60px; 
        }
        .icon {
          width: 32px; 
        }
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  } 
  @media (max-width: 700px) {
    .wrapper {
      padding-top: 2.857vw;
      padding-bottom: 2.857vw;
    }
    .logo-merck {
      .logo {
        width: 21.428vw; 
      } 
    }
    ul.sns { 
      li {
        a { 
          width: 8.571vw;
          height: 8.571vw; 
        }
        .icon {
          width: 4.571vw; 
        }
        &:not(:last-child) {
          margin-right: 1.714vw;
        }
      }
    }

  }
} 
</style>