export default [
  '여우원숭이',
  '토끼',
  '불곰',
  '호랑이',
  '바다거북',
  '미어캣',
  '라마',
  '알파카',
  '푸들',
  '도마뱀',
  '악어',
  '올빼미',
  '부엉이',
  '코뿔소',
  '늑대',
  '코끼리',
  '삵',
  '족제비',
  '치타',
  '노루',
  '고라니',
  '낙타',
  '고릴라',
  '뱀',
  '너구리',
  '담비',
  '고슴도치',
  '친칠라',
  '금붕어',
  '기니피그',
  '이구아나',
  '불독',
  '표범',
  '설표',
  '포메라니안',
  '라쿤',
  '기린',
  '하마',
  '조랑말',
  '오랑우탄',
  '반달곰',
  '하늘다람쥐',
  '개코원숭이',
  '비숑',
  '팬더',
  '오소리',
  '무당벌레',
  '다금바리',
  '복어',
  '달팽이',
  '개복치',
  '두더지',
  '사막여우',
  '양',
  '웰시코기',
  '홍게',
  '카피바라',
  '쿼카',
  '참새',
  '도다리',
  '핏불테리어',
  '차우차우',
  '도롱뇽',
  '황새',
  '거위',
  '바다코끼리',
  '물개',
  '펭귄',
  '철갑상어',
  '달마시안',
  '시츄',
  '비글',
  '장수말벌',
  '청개구리',
  '사마귀',
  '임연수',
  '피라냐',
  '흰수염고래',
  '바닷가재',
  '해마',
  '오골계',
  '물범',
  '당나귀',
  '순록',
  '미꾸라지',
  '도요새',
  '황소개구리',
  '비단털쥐',
  '햄스터',
  '주머니쥐',
  '뱁새',
  '치와와',
  '캥거루',
  '돼지',
  '비버',
  '산미치광이',
  '타조',
  '공작새',
  '전갈',
  '비글',
];
