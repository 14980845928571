<template>
  <div class="ma-auto" style="max-width:450px;">
    <v-otp-input v-model="password" type="password" length="8" :disabled="loading" @finish="submit"></v-otp-input>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" color="warning" :timeout="2000">
      {{ error }}
    </v-snackbar>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth';

export default {
  data() {
    return {
      email: 'dev@insand.co.kr',
      password: '',
      error: null,
      loading: false,
      snackbar: false,
    }
  },
  methods: {
    submit() {
      this.loading = true
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.loading = false
        })
        .catch(err => {
          this.error = err.message
          this.snackbar = true
          this.loading = false
        })
    },
  },
}
</script>
