import Vue from 'vue';
import Router from 'vue-router';
import EventPage from './components/EventPage';   
import Result from './components/admin/Result';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'event',
      component: EventPage,
    },
    {
      path: '/admin',
      name: 'admin',
      component: Result,
    },
    {
      path: '*',
      redirect: '/'
    },
  ],
});

export default router;
