export default [
  '고장난',
  '깨끗한',
  '공손한',
  '신박한',
  '상냥한',
  '어색한',
  '빵터진',
  '성실한',
  '점잖은',
  '핫한',
  '귀여운',
  '멋진',
  '잘생긴',
  '키가 큰',
  '조금 작은',
  '조금 큰',
  '많이 작은',
  '많이 큰',
  '간지러운',
  '화려한',
  '인싸',
  '아싸',
  '외로운',
  '인기 많은',
  '거친',
  '포악한',
  '순한',
  '배려심 많은',
  '열정적인',
  '소심한',
  '냉정한',
  '부담스러운',
  '거센',
  '당연한',
  '불가능한',
  '가능성 있는',
  '집착적인',
  '떠도는',
  '얼큰한',
  '안타까운',
  '어리둥절한',
  '씁쓸한',
  '똑똑한',
  '개그맨',
  '깐깐한',
  '집요한',
  '웃긴',
  '힘이 약한',
  '힘이 쎈',
  '매우 화가 난',
  '차분한',
  '봉변 당한',
  '무던한',
  '무난한',
  '눈치 보는',
  '눈치 없는',
  '눈을 뗄 수 없는',
  '지각한',
  '아이돌 뺨치는',
  '눈물버튼',
  '철벽 치는',
  '흥미로운',
  '사과하는',
  '양보하는',
  '계속 먹는',
  '연기하는',
  '노래하는',
  '글쓰는',
  '춤추는',
  '셀카 찍는',
  '희한한',
  '유쾌한',
  '투명한',
  '랩하는',
  '시크한',
  '기가 찬',
  '왕년에',
  '전생에',
  '척척박사',
  '통통한',
  '헬스하는',
  '힙한',
  '염색한',
  '푸근한',
  '부지런한',
  '재벌',
  '고고한',
  '안전한',
  '정확한',
  '양해하는',
  '손실난',
  '인내하는',
  '보살',
  '경청하는',
  '가발 쓴',
  '옷 입은',
  '신발 신은',
  '밥 먹는',
  '멍 때리는',
  '은신하는',
];
