<template>
  <v-app>
    <login v-if="!isLoading && !user.loggedIn" />
    <template v-if="user.loggedIn">
      <v-app-bar dark app>
        <v-toolbar-title>대나무숲 관리</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="logout" icon>
          <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <v-btn absolute right elevation="10" icon large>
            <download-excel :data="daenamus" :fields="excel_fields" worksheet="result" name="daenamu_result.xls">
              <v-icon color="purple">mdi-file-excel</v-icon>
            </download-excel>
          </v-btn>
          <v-subheader>메시지 목록</v-subheader>
          <v-data-table
            id="table-daenamus"
            :calculate-widths="false"
            :headers="headers"
            :items="daenamus"
            :items-per-page="-1"
            :hide-default-footer="true"
            class="elevation-1"
          >
            <!-- <template slot="item.index" slot-scope="props">
              {{ props.index + 1 }}
            </template> -->
            <!-- <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template> -->
            <template v-slot:[`item`]="{ item, index, headers }">
              <tr class="tr-item">
                <td class="td-index" rowspan="2">{{ index + 1 }}</td>
                <td>
                  <v-chip dark color="orange">{{ item.count }}</v-chip>
                </td>
                <td>{{ item.nickname }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.date }}</td>
                <td>
                  <v-icon small color="black" @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
              <tr>
                <td :colspan="headers.length - 1">
                  <v-card outlined color="msg-card">
                    <v-card-text class="msg">
                      {{ item.message }}
                    </v-card-text>
                  </v-card>
                </td>
              </tr>
            </template>
            <!-- <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div class="msg">{{ item.message }}</div>
              </td>
            </template> -->
            <!-- <template v-slot:no-data> </template> -->
          </v-data-table>
        </v-container>
      </v-main>
    </template>
  </v-app>
</template>

<script>
import downloadExcel from 'vue-json-excel'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Login from './Login.vue'
import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';

export default {
  components: {
    downloadExcel,
    Login,
  },
  data() {
    return {
      headers: [
        {
          text: '번호',
          sortable: false,
          // align: 'start',
          // value: 'index',
        },
        { text: '좋아요', value: 'count' },
        { text: '닉네임', value: 'nickname' },
        // { text: '메시지', value: 'data-table-expand' },
        { text: '휴대폰번호', value: 'phone' },
        { text: '작성일', value: 'date' },
        { text: '', sortable: false },
      ],
      daenamus: [],
      excel_fields: {
        좋아요: 'count',
        닉네임: 'nickname',
        휴대폰번호: 'phone',
        작성일: 'date',
        메시지: 'message',
      },
    }
  },
  computed: {
    ...mapGetters(['user', 'isLoading']),
  },
  methods: {
    ...mapMutations(['setLoading']),
    ...mapActions(['fetchUser']),
    logout() {
      firebase.auth().signOut()
    },
    deleteItem(item) {
      if (confirm(`${item.nickname}님 메시지를 삭제하시겠습니까?`)) {
        firebase
          .firestore()
          .collection('daenamu')
          .doc(item.id)
          .delete()
          .then(() => {
            this.daenamus = this.daenamus.filter(daenamu => daenamu.id !== item.id)
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
  },
  created() {
    this.setLoading(true)
    firebase.auth().onAuthStateChanged(user => {
      this.fetchUser(user)

      if (this.user.loggedIn) {
        firebase
          .firestore()
          .collection('daenamu')
          .get()
          .then(snapshot => {
            this.daenamus = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }))
            this.setLoading(false)
          })
      } else {
        this.setLoading(false)
      }
    })
  },
}
</script>

<style lang="scss">
#table-daenamus {
  th,
  td {
    vertical-align: middle;
  }
  .tr-item {
    .td-index {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
    td {
      border-bottom: 0;
    }
  }
  tr {
    background: none;
  }
  .msg-card {
    border-color: #f7f7f7;
    padding: 10px 20px;
    margin-bottom: 10px;
    .msg {
      padding: 10px 0;
      word-break: break-all;
      white-space: normal;
    }
  }
}
</style>
