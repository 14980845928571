import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    isLoading: false,
    user: {
      loggedIn: false,
      data: null,
    },
  },
  getters: {
    isLoading: function(state) {
      return state.isLoading;
    },
    user(state) {
      return state.user;
    },
  },
  mutations: {
    setLoading: function(state, payload) {
      return (state.isLoading = payload);
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null);

      if (user) {
        commit('SET_USER', {
          displayName: user.displayName,
          email: user.email,
        });
      } else {
        commit('SET_USER', null);
      }
    },
  },
});
