<template>
  <div>
    <Overlay />
    <Header />
    <div class="event-page" ref="eventPage">   
      <div class="btn-floating-daenamu" v-if="showFloatingBtn" v-scroll-to="'#scrollTarget'"> 
        <button><img src="@/assets/images/icon/ic_daenamu.png" alt=""><span>대나무 작성하기</span></button>
      </div>
      <div class="btn-share floating" :class="{'fixed': isFixed}" @click="shareWebSite"><icon name="share" class="icon-share"/>공유하기</div>
      <section class="event-main"> 
        <div class="event-main-round">
          <div class="wrapper">
            <div class="event-main__title">
              <span class="sub">임금님 귀는 당나귀 귀~</span>
              <h2>연말 연구실 <br/>대나무 숲</h2>
              <p>말도 많았던 <span class="vdn">2021</span>년 하소연 이 곳에 날려버리세요<span class="img"><img src="@/assets/images/icon/ic_bye.png" alt="👋"></span><br/> 많은 공감을 얻은 분에게는 선물을 드립니다.</p>
            </div>
            <div class="event-main__contents">
              <div class="chat"><img src="@/assets/images/main/img_chat_01.png" alt="" class="pc"><img src="@/assets/images/main/img_chat_m_01.png" alt="" class="mo"></div>
              <div class="chat"><img src="@/assets/images/main/img_chat_02.png" alt="" class="pc"><img src="@/assets/images/main/img_chat_m_02.png" alt="" class="mo"></div>
              <div class="chat"><img src="@/assets/images/main/img_chat_03.png" alt=""></div> 
            </div>
          </div> 
        </div>
      </section>
      <section class="event-info">
        <div class="wrapper"> 
          <div class="event-contents__title">
            <div class="label"><span class="vdn">#2021</span>년 마무리</div>
            <h3>여러분의<br/><span>연구실 하소연</span>을<br/>들려주세요.</h3>
          </div>
          <div class="event-info__contents">
            <div class="info">
              <img src="@/assets/images/info/bg_gift_05.png" alt="" class="bg-info-gift">
              <div class="info__title"># 이렇게 하세요</div>  
              <ul class="info__lists">
                <li><div class="num">1.</div><div>코멘트 창에 나의 <span class="text__accent font-bold"><span class="vdn">2021</span> 연구생활 하소연</span> 혹은 <br/><span class="text__accent font-bold"><span class="vdn">2022</span> 다짐</span>을 입력하세요.</div></li> 
                <li><div class="num">2.</div><div>대나무 박스를 둘러보고 공감하는 박스에 <br/><img src="@/assets/images/info/ic_good.png" alt="good" class="icon-good"> <span class="text__accent font-bold">‘좋아요’</span>를 눌러주세요.</div></li> 
                <li><div class="num">3.</div><div>가장 많은 좋아요를 얻은 분, 럭키 넘버 순서에 작성하신 분, 웃픈 공감 내용을 작성하신 분에게 <span class="text__accent font-bold">연말 선물</span>을 드립니다. 어떤 분에게 어떤 선물이 갈지 몰라요! 선물 보따리는 무한 확장 중이니, 사소한 내용이라도 공유해보세요! <img src="@/assets/images/icon/ic_smile_face.png" alt="" class="icon-smile"></div></li> 
              </ul> 
            </div>
            <div class="gift">
              <img src="@/assets/images/info/img_gift.png" alt="gift" class="pc">
              <img src="@/assets/images/info/img_gift_mo.png" alt="gift" class="mo">
            </div>
          </div>  
        </div>
      </section>
      <section class="event-form" data-app ref="scrollContent" id="scrollTarget">
        <div class="wrapper">
          <div class="event-form__contents">
            <div class="input-nickname">
              <span class="input-nickname__label">닉네임</span>
              <div class="input-nickname__input">
                <span v-text="form.nickname"></span>
                <button type="button" @click="changeNickname">딴거 할래요</button>
              </div>
            </div>
            <div class="input-form">
              <v-form
                ref="form"
                v-model="valid"
                class="input-form__form"
                lazy-validation
              >
                <div class="input-form__phone">
                  <v-text-field
                    v-model="form.phone"
                    type="tel"
                    :rules="phoneRules" 
                    solo
                    flat
                    placeholder="“-”를 제외한 휴대폰 번호를 공백없이 입력해주세요."
                    class="input-form__input"
                    hide-details
                    required
                    maxlength="11"
                  ></v-text-field>
                  <span class="text">경품 증정용으로만 수집합니다.</span>
                </div>
                <div class="input-form__message">
                  <v-textarea 
                    v-model="form.message"  
                    :rules="messageRules"
                    solo
                    flat 
                    :counter="150"
                    rows="4"
                    no-resize 
                    placeholder="부적절(비방, 욕설, 희롱 등)한 대나무숲 작성글은 관리자에 의해 임의로 삭제 처리될 수 있습니다."
                    class="input-form__textarea" 
                    required
                    maxlength="150"
                  ></v-textarea> 
                  <v-btn
                    :disabled="!valid"
                    color="#e61e50"
                    depressed
                    height="auto"
                    :ripple="false"
                    class="input-form__button"
                    >등록하기</v-btn
                  >
                </div>
              </v-form>
            </div>
          </div>
          <div class="event-form__share">
            <div class="text-share"><p>내 공감수를 늘리고 싶다면?</p><p>동료들에게 공유하세요!</p></div>
            <div class="btn-share" @click="shareWebSite"><icon name="share" class="icon-share"/>공유하기</div>
          </div>
        </div>
      </section> 
      <section class="event-list">
        <div class="wrapper">
          <div class="event-list__filter" v-if="daenamus.length">
            <button v-if="order_by === 'count'" type="button" @click="orderByDate"><icon name="filter" class="icon" />공감순</button>
            <button v-else type="button" @click="orderByCount"><icon name="filter" class="icon" />최신순</button>
          </div>
          <div class="event-list__contents pc" v-if="!mobile">
            <div class="daenamu" v-for="daenamu in daenamus" :key="daenamu.id">
              <div class="inner">
                <div class="daenamu__name">{{ daenamu.nickname }}</div>
                <div class="daenamu__message">{{ daenamu.message }}</div>
                <div class="daenamu__thumbsup">
                  <div class="count">
                    <span><icon name="thumbsup" class="icon" /></span>{{ daenamu.count }}
                  </div>
                  <div>
                    <button type="button" :class="{'active':likes.includes(daenamu.id)}" @click="applyLike(daenamu)">
                      <icon name="thumbsup" class="icon" />
                    </button> 
                  </div>
                </div> 
              </div>
              <icon name="tail" class="icon-tail" />
            </div> 
          </div>
          <div class="event-list__contents mo">
            <swiper ref="mySwiper" :options="swiperOptions" @slideChange="changeSwiperIndex">
              <swiper-slide class="daenamu" v-for="daenamu in daenamus" :key="daenamu.id">
                <div class="inner">
                  <div class="daenamu__name">{{ daenamu.nickname }}</div>
                  <div class="daenamu__message">{{ daenamu.message }}</div>
                  <div class="daenamu__thumbsup">
                    <div class="count">
                      <span><icon name="thumbsup" class="icon" /></span>{{ daenamu.count }}
                    </div>
                    <div>
                      <button type="button" :class="{'active':likes.includes(daenamu.id)}" @click="applyLike(daenamu)">
                        <icon name="thumbsup" class="icon" />
                      </button> 
                    </div>
                  </div> 
                </div>
                <icon name="tail" class="icon-tail" />
              </swiper-slide> 
            </swiper> 
          </div>
        </div>
      </section>
      <section class="event-subscribe" :class="{'more-area': isMore}" data-app>
        <div v-if="isMore && !mobile" class="event-more"> 
          <button type="button" :disabled="loadingDaenamu" @click.prevent="getMoreDaenamus"><img src="@/assets/images/icon/ic_eye.png" alt=""> 더보기</button>
        </div> 
        <div class="event-subscribe-round">
          <div class="wrapper">
            <div class="event-subscribe__contents">
              <div class="event-contents__title">
                <div class="label"><span class="vdn">#</span>뉴스레터</div>
                <h3>연구에 참고할<br/><span>최신 뉴스레터</span>를<br/>받아보고 싶다면?</h3>
              </div>
              <div class="subscribe-form"> 
                <v-form
                  ref="subscribe_form"
                  v-model="subscribe_valid"
                  class="subscribe-form__form"
                  lazy-validation
                  v-if="!isSubscribed"
                >  
                <div class="input-area">
                  <div class="input-area__text">
                    <v-text-field
                      v-model="subscribe_form.email"
                      :rules="emailRules"
                      solo
                      flat
                      class="subscribe-form__input"
                      placeholder="이메일 주소를 입력해주세요." 
                      hide-details
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="subscribe_form.name"
                      :rules="nameRules"
                      solo
                      flat
                      class="subscribe-form__input"
                      placeholder="성함을 입력해주세요." 
                      hide-details
                      required
                    ></v-text-field> 
                  </div>
                  <v-btn 
                    :disabled="!subscribe_valid"
                    @click="subscribe()"
                    color="#e61e50"
                    depressed
                    height="auto"
                    :ripple="false"
                    class="subscribe-form__button subscribe-form__button-pc"
                    >뉴스레터 무료로 구독하기</v-btn
                  >  
                </div>
                <div class="checkbox-area"> 
                  <div class="checkbox-area__checkbox" :class="{'active': showAgree1}"> 
                    <v-checkbox
                      v-model="subscribe_form.agree1"
                      :rules="checkRules"
                      color="#ffffff" 
                      off-icon="mdi-check"
                      on-icon="mdi-check" 
                      class="subscribe-form__checkbox" 
                      hide-details  
                      :ripple="false" 
                    >
                    <template v-slot:label>
                      <span>[필수] 개인정보 수집 · 이용에 동의합니다.</span>
                    </template>
                    </v-checkbox>  
                    <button type="button" @click="clickShowAgree1">내용 보기<img src="@/assets/images/icon/ic_close.png" alt=""></button> 
                  </div>
                  <div class="checkbox-area__desc" v-if="showAgree1">
                    본인은 대한민국 개인정보보호법령 및 유럽 일반개인정보호규정 <span class="vdn">(GDPR)</span>에 따라 머크와의 거래관계 설정 및 유지, 계약의 이행을 위하여 성명, 소속 기관명, 부서 및 직함, 전화번호, 휴대전화번호, 전자우편주소, 주소의 개인 정보를 제공하는 것에 동의합니다. 수집된 개인 정보는 개인정보의 수집 및 이용 목적이 달성되면 지체없이 파기하되, 관계 법령의 규정에 따라 귀하의 개인정보를 보존할 의무가 있는 경우에 해당 법령에서 정한 바에 의하여 보유할 수 있습니다. 귀하는 언제든지 <a href="https://www.sigmaaldrich.com/KR/ko/life-science/legal/privacy-statement#privacy6" target="_blank" rel="noopener noreferrer">개인정보의 수집 및 이용 동의를 철회</a>할 수 있습니다. 보다 자세한 내용은 머크의 <a href="https://www.sigmaaldrich.com/KR/ko/life-science/legal/privacy-statement" target="_blank" rel="noopener noreferrer">개인정보 처리방침</a> 문서를 참고하실 수 있습니다.
                  </div>
                  <div class="checkbox-area__checkbox" :class="{'active': showAgree2}">
                    <v-checkbox
                      v-model="subscribe_form.agree2"
                      :rules="checkRules"
                      color="#ffffff" 
                      off-icon="mdi-check"
                      on-icon="mdi-check" 
                      class="subscribe-form__checkbox" 
                      hide-details  
                      :ripple="false" 
                    >
                      <template v-slot:label>
                        <span>[필수] 광고성 정보 수신에 동의합니다.</span>
                      </template>
                    </v-checkbox> 
                    <button type="button" @click="clickShowAgree2">내용 보기<img src="@/assets/images/icon/ic_close.png" alt=""></button> 
                  </div>   
                  <div class="checkbox-area__desc" v-if="showAgree2">
                    본인은 머크에 제공된 상기 정보가 우편(전자우편 포함) 발송, 방문, 전화(휴대전화, 유선전화, 팩스 등), 인터넷(카페, 블로그, SNS 쪽지 등), 메신저 등을 통한 제품설명회 등 마케팅, 시장조사 및 회사 제품의 안내를 포함한 제품 정보 전달 및 취득 활동의 수행 등에 이용될 수 있음을 명확히 이해했고 이에 동의합니다.
                  </div>
                </div> 
                <v-btn 
                  :disabled="!subscribe_valid"
                  @click="subscribe()"
                  color="#e61e50"
                  depressed
                  height="auto"
                  :ripple="false"
                  class="subscribe-form__button subscribe-form__button-mo"
                  >뉴스레터 무료로 구독하기</v-btn
                >  
                </v-form> 
                <div v-else class="subscribe-form__complete">뉴스레터 구독 신청이 <br/>완료되었습니다!</div>
              </div> 
            </div> 
            <div class="event-subscribe__banners">
              <div class="banner">
                <div class="banner__title"><span class="vdn">Avanti® Polar lipid</span> 제품 할인</div>
                <div class="banner__text"><span class="vdn">Lipidomics</span>를 위한 <span class="vdn">99%</span> 고순도 제품 <span class="vdn">400</span>여종 <span class="vdn">20%</span> 할인 받고 구매 / 프로모션 코드: <span class="vdn">UDB</span></div>
                <div class="banner__img" @click="clickBanner01"><img src="@/assets/images/newsletter/img_banner_01.png" alt=""></div> 
              </div>
              <div class="banner">
                <div class="banner__title">머크 재료과학 저널 무료 구독</div>
                <div class="banner__text"><span class="vdn">Materials Matters™ 16.2</span> 발간!</div>
                <div class="banner__img" @click="clickBanner02"><img src="@/assets/images/newsletter/img_banner_02.png" alt=""></div>  
              </div>
              <div class="banner">
                <div class="banner__title">연구에 필요한 제품을 <span class="vdn">workflow</span> 별로 한눈에!</div>
                <div class="banner__text">더 이상 어렵게 찾지 마세요</div>
                <div class="banner__img" @click="clickBanner03"><img src="@/assets/images/newsletter/img_banner_03.png" alt=""></div>   
              </div>
            </div>
          </div>
        </div>
      </section> 
    </div>
    <v-overlay :value="isLoading" :opacity="0.6" style="z-index: 9999;">
      <v-progress-circular
        :size="50"
        color="#E61E50"
        indeterminate
      ></v-progress-circular>
    </v-overlay> 
    <Footer />
  </div>
</template>
  
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import firstName from "@/assets/first_name.js";
import secondName from "@/assets/second_name.js";
import Icon from "@/components/Icon";
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { mapMutations } from 'vuex'

import Overlay from '@/components/Overlay'

export default { 
  components: { 
    Header,
    Footer,
    Icon,
    swiper,
    swiperSlide,
    Overlay,
  }, 
  data() {
    return {
      mobile: false,
      valid: true,
      subscribe_valid: true,
      daenamus: [],
      isMore: false,
      isSubscribed: false,
      likes: [],
      order_by: 'count', 
      form: {
        nickname: this.getNickname(),
        phone: "",
      },
      loadingDaenamu: false,
      subscribe_form: {
        email: "",
        name: "",
        agree1: false,
        agree2: false,
      },
      emailRules: [
        (v) => !!v,
        v => /.+@.+\..+/.test(v)
      ],
      nameRules: [(v) => !!v ],
      phoneRules: [
        (v) => !!v,
        (v) => /^[0-9]*$/.test(v), 
        (v) => !(v && v.length < 9)
      ],
      messageRules: [ 
        (v) => !!v,  
         v => (v || '' ).length <= 150,  
      ],
      checkRules: [v => !!v], 
      isFixed: false,
      swiperOptions: {
        slidesPerView: 'auto',
        centeredSlides: true,   
        centeredSlidesBounds: true,
        spaceBetween: 20,   
      },
      showFloatingBtn: true,
      showAgree1: false,
      showAgree2: false
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    }
  },
  created() {
    this.likes = JSON.parse(localStorage.getItem('like')) || []
  },
  mounted() {
    const pc_device = "win16|win32|win64|mac|macintel";
    const device = navigator.platform;
    if (device) {
      this.mobile =
        pc_device.indexOf(navigator.platform.toLowerCase()) < 0 ? true : false;
    }
    window.addEventListener('scroll', this.handleScroll)
    this.getDaenamus();
  }, 
  beforeDestory() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapMutations(['setLoading']),
    shareWebSite() {
      const shareTitle = '연말 연구실 대나무 숲'
      const shareText = '말도 많았던 2021년 하소연 이 곳에 날려버리세요👋 많은 공감을 얻은 분에게는 선물을 드립니다.' 
      const shareURL = 'http://shareyourthing.merck.insand.co.kr/'
      if(navigator.share) {
        navigator.share({
          title: shareTitle,
          text: shareText,
          url: shareURL
        }).then(() => {
          console.log('success')
        }).catch((e) => {
          console.log('error', e)
        })
      } else {
        this.$copyText(shareURL).then((e) => {
          alert('클립보드에 URL이 복사되었습니다.') 
        }) 
      }
    },
    changeSwiperIndex() {  
      if(this.isMore && !this.loadingDaenamu && (this.$refs.mySwiper.swiper.activeIndex >= (this.daenamus.length - 3)) ) {
        this.getMoreDaenamus()
      } 
    },
    handleScroll() {  
      if(!this.$refs.eventPage) return
      this.isFixed = window.scrollY > this.$refs.eventPage.offsetTop  

      const windowBottom = window.innerHeight + window.pageYOffset;   
      const formAreaTop = this.$refs.scrollContent.offsetTop; 
      this.showFloatingBtn = formAreaTop < windowBottom ? false : true;   
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.setLoading(true)
        this.$http.post("/", this.form)
          .then(() => {
            this.$refs.form.reset();
            this.changeNickname();
            this.orderByDate();
            this.setLoading(false)
          })
          .catch(this.errorAlert);
      }
    },
    getDaenamus() { 
      // this.setLoading(true)
      // this.$http.get("/", { params: { order: this.order_by } })
      //   .then(({ data }) => {
      //     this.daenamus = data.daenamus
      //     this.isMore = data.isMore 
      //     this.setLoading(false)
      //   })
      //   .catch(this.errorAlert);
    },
    getMoreDaenamus() { 
      // this.loadingDaenamu = true
      // this.$http.get("/", { params: { order: this.order_by, next: this.daenamus[this.daenamus.length - 1].id } })
      //   .then(({ data }) => {
      //     this.loadingDaenamu = false
      //     this.daenamus = [...this.daenamus, ...data.daenamus]
      //     this.isMore = data.isMore
      //   })
      //   .catch(this.errorAlert)
    },
    subscribe() {
      // if (this.$refs.subscribe_form.validate()) {
      //   this.setLoading(true)
      //   this.$http.post("subscribe", this.subscribe_form).then(() => {
      //       window.kakaoPixel('179773457207741262').pageView();
      //       window.kakaoPixel('179773457207741262').signUp('Subscription');
      //       this.setLoading(false)
      //       this.isSubscribed = true;
      //       this.$refs.subscribe_form.reset();
      //     })
      //     .catch(this.errorAlert)
      // }
    },
    getNickname() {
      return (
        firstName[Math.floor(Math.random() * firstName.length)] +
        " " +
        secondName[Math.floor(Math.random() * secondName.length)]
      );
    },
    changeNickname() {
      this.form.nickname = this.getNickname();
    },
    applyLike(daenamu) {
      if(!this.likes.includes(daenamu.id)) {
        this.setLoading(true)
        this.likes.push(daenamu.id)
        this.$http.patch("/", {id:daenamu.id, action:'increment'})
          .then(()=> {
            daenamu.count++
            this.setLoading(false)
          })
          .catch(this.errorAlert)
      } else {
        this.likes = this.likes.filter(like => like !== daenamu.id)
        if(daenamu.count > 0) {
          this.$http.patch("/", {id:daenamu.id, action:'decrement'})
            .then(()=>daenamu.count--)
            .catch(this.errorAlert)
        }
      }
      localStorage.setItem('like', JSON.stringify(this.likes))
    },
    orderByCount() {
      this.order_by = 'count'
      this.getDaenamus()
    },
    orderByDate() {
      this.order_by = 'date'
      this.getDaenamus()
    },
    errorAlert() {
      alert("다시 시도해주세요.");
    },
    clickShowAgree1() {
      this.showAgree1 = !this.showAgree1 
    },
    clickShowAgree2() {
      this.showAgree2 = !this.showAgree2 
    },
    clickBanner01() {
      window.kakaoPixel('179773457207741262').addToWishList({
        id: 'avantipromo',
        tag: 'bannerclick'
      });
      window.open('https://www.sigmaaldrich.com/KR/ko/campaigns/avanti-lipidomics-research-resources?utm_source=microsite?utm_medium=banner?utm_campaign=avantipromo')
      
    },
    clickBanner02() {
      window.kakaoPixel('179773457207741262').addToWishList({
        id: 'mm-subscription',
        tag: 'bannerclick'
      });
      window.open('https://www.sigmaaldrich.com/KR/ko/program/mm-subscription?utm_source=microsite?utm_medium=banner?utm_campaign=mm-subscription-kr')
    },
    clickBanner03() {
      window.kakaoPixel('179773457207741262').addToWishList({
        id: 'e-workflow',
        tag: 'bannerclick'
      });
      window.open('https://www.sigmaaldrich.com/KR/ko/campaign-kr/e-workflow?utm_source=microsite?utm_medium=banner?utm_campaign=eworkflow-kr')
    }
  },
};
</script>

<style lang="scss">
.event-page {
  position: relative;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto; 
  overflow: hidden;
  .btn-floating-daenamu {
    display: none;
    position: fixed; 
    left: 0;
    right: 0;
    bottom: 40px;
    width: 100%;
    text-align: center;
    z-index: 9999;
    button { 
      display: inline-flex;
      align-items: center;
      justify-content: center; 
      padding: 0.813em 2.500em;
      font-size: 32px;
      background: $white;
      border-radius: 999px;
      box-shadow: 0px 10px 11.16px 0.84px rgba(75, 75, 75, 0.4);
      animation: bounce 1.5s ease-in-out infinite;
      span {
        font-weight: 700;
        color: $darkgreen;
      }
      img {
        width: 1.125em;
        margin-right: 0.438em;
      }
    }
  }
  .btn-share { 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.875em 1.250em;
    font-size: 16px;
    background: $white;
    border-radius: 999px;
    color: $red;
    font-weight: bold;
    box-shadow: 0px 4px 11.16px 0.84px rgba(75, 75, 75, 0.2);
    cursor: pointer;
    .icon-share {
      display: block;
      margin-right: 0.625em;
      width: 0.938em;
      height: 1.250em;
      stroke: currentColor;
      fill:currentColor;
      color: $red;
    }
    &.floating {
      position: absolute;
      top: 2.500em;
      right: calc(50% - 910px);
      z-index: 999;
    }
    &.fixed {
      position: fixed;
    }
  } 
  .event-main {
    background: $darkgreen;
    margin-bottom: -1px;
    &-round {
      background-color: $red;
      background-image: url('../assets/images/main/bg.png');
      background-position: bottom center;
      background-size: auto;
      border-bottom-left-radius: 200px;
      border-bottom-right-radius: 200px; 
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 68px;
      padding-bottom: 68px;
    }
    &__title {
      color: $yellow;
      .sub {
        display: inline-block;
        margin-bottom: 0.571em;
        font-size: 28px;
        font-weight: 500;
      }
      h2 {
        margin-bottom: 0.706em;
        font-size: 68px;
        font-weight: 700;
        line-height: 1.2;
      }
      p {
        font-size: 20px;
        font-weight: 500;
        color: $white;
        line-height: 1.5;
        img {
          width: 1.000em;
          vertical-align: baseline;
        }
        .img {
          vertical-align: middle;
        }
      }
    }
    &__contents { 
      .chat {
        img { 
          max-width: 400px;
          &.pc {display: block;}
          &.mo {display: none;}
        } 
        &:nth-child(1) { 
          margin-left: 80px; 
          margin-bottom: 18px; 
          img { 
            animation: fade-in-right 0.6s 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          }
        }
        &:nth-child(2) {
          margin-right: 80px; 
          margin-bottom: 18px; 
          img { 
            animation: fade-in-left 0.6s 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          }
        }
        &:nth-child(3) { 
          margin-left: 80px;  
          img { 
            animation: fade-in-right 0.6s 1.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          }
        }  
      }
    }
  }
  .event-info {
    background-color: $darkgreen;
    background-image: url('../assets/images/info/bg_gift_01.png'), url('../assets/images/info/bg_gift_02.png'), url('../assets/images/info/bg_gift_03.png');
    background-size: 227px auto, 252px auto, 142px auto;
    background-position: left calc(100% + 36px), calc(50% - 370px) calc(50% + 175px), right 80px;
    .wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 81px;
      padding-bottom: 74px;
    }
    &__contents {
      .info {
        max-width: 724px;
        padding: 2.500em;
        margin-bottom: 3.750em;
        font-size: 16px;
        background: $red;
        border-radius: 1.875em;
        color: $white;
        .bg-info-gift {
          display: none;
        }
        &__title {
          margin-bottom: 0.571em;
          padding-bottom: 0.571em;
          border-bottom: 1px solid $white;
          font-size: 28px;
          font-weight: 700;
        }
        &__lists {
          li {
            display: flex;
            align-items: flex-start;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.5;
            .num {
              margin-right: 1em;
            }
            .text__accent {
              color: $yellow;
            }
            img {
              width: 1.200em;
              vertical-align: text-top;
            }
            &:not(:last-child) {
              margin-bottom: 0.700em;
            }
          }
          br { display: none; }
        }
      }
      .gift {
        max-width: 720px;
        .pc {display: inline-block;}
        .mo {display: none;}
      }
    }
  }
  .event-form {
    background: $yellow;
    .wrapper {
      padding-top: 60px;
      padding-bottom: 40px;
    }
    &__contents {
      width: 100%;
      max-width: 776px;
      margin: 0 auto; 
      margin-bottom: 20px;
      .input-nickname {
        display: flex;
        flex-direction: column; 
        max-width: 320px;
        margin-bottom: 16px;
        &__label {
          align-self: flex-start;
          display: inline-block;
          padding: 6px 10px; 
          margin-bottom: 1px;
          background: #f4b406;
          border-radius: 999px;
          font-weight: 700;
          font-size: 14px;
          color: $white;
          cursor: default;
        }
        &__input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 0;
          border-bottom: 1px solid #ffe293;
          span {
            font-size: 20px;
            font-weight: 700;
            color: $black;
          }
          button {
            padding: 0.643em 0.929em;
            background: $red;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 700;
            color: $white;
            &:hover {
              background: #d71d4c;
            }
          } 
        }
      }
      .input-form { 
        &__input {
          background: $white;
          border-radius: 12px;
          .v-input__slot {
            padding: 10px 24px !important; 
          }
          input { 
            font-weight: 500;
            font-size: 16px;
            font-family: 'Verdana Pro';
          }
          input::placeholder { 
            font-family: 'Noto Sans KR';
            letter-spacing: -0.05em;
            color: #bbbbbb;
          }
          &.error--text { 
            box-shadow: 0px 0px 0px 2px $red;
          }
        }
        &__textarea {
          position: relative;
          border-radius: 0;
          .v-input__slot {
            padding: 24px !important;
            margin-bottom: 0 !important;
          }
          .v-text-field__details {
            position: absolute;
            right: 12px;
            bottom: 8px;
            margin-bottom: 0 !important;
            .v-counter {
              font-family: 'Verdana Pro';
            }
          }
          textarea {
            margin-top: 0 !important;
            font-size: 16px;
            font-weight: 500; 
            &::placeholder { 
              font-family: 'Noto-Ver';
              letter-spacing: -0.05em;
              color: #bbbbbb;
            }
          }
        }
        &__button {
          border-radius: 0;
          padding: 0 32px !important;
          font-weight: 700;
          font-size: 20px;
          color: $white;
          &.v-btn--disabled.v-btn--has-bg {
            background-color: #e61e50aa !important;
            color: #ffffffaa !important;
          }
        }
        &__phone {
          position: relative; 
          margin-bottom: 8px; 
          .text {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            padding-right: 30px;
            font-size: 13px;
            font-weight: 500;
            color: $darkgreen;
          }
        }
        &__message {
          display: flex;
          border-radius: 20px;
          overflow: hidden;
        }
      }
    }
    &__share {
      display: flex;
      align-items: center;
      justify-content: center;
      .text-share {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 25px;
        gap: 4px;
        p {
          display: block;
          position: relative; 
          font-size: 20px;
          font-weight: 700;
          letter-spacing: -0.04em;
          z-index: 1;
          &::after {
            content: '';
            position: absolute; 
            left: -1.5%;
            right: 0;
            bottom: -2px;
            width: 103%;
            height: 70%;
            background: $lightyellow;
            z-index: -1;
          }
        }
      }
    }
  }
  .event-list {
    position: relative;
    background: $yellow; 
    &__filter { 
      margin-bottom: 12px;
      button {
        display: flex;
        align-items: center;
        margin-left: auto;
        padding: 1.000em 1.250em;
        font-size: 16px;
        background: $red;
        border-radius: 999px;
        color: $white;
        font-weight: 700;
        box-shadow: 0px 4px 9.3px 0.7px rgba(134, 109, 39, 0.3);
        .icon {
          margin-right: 0.500em;
          width: 0.938em;
          fill: currentColor;
          color: $yellow;
        } 
        &:hover {
          background: #d71d4c;
        }
      }
    }
    &__contents {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 20px;
      row-gap: 40px;
      padding-bottom: 80px;
      .daenamu {
        position: relative;
        padding: 8px;
        background: $red;
        border-radius: 40px;
        .inner { 
          width: 100%;
          height: 100%;
          padding: 20px;
          border-radius: 34px;
          border: 2px dashed $yellow;
          background-image: url('../assets/images/message/img_tree.svg');
          background-position: calc(100% - 20px) top ;
          background-size: 36px auto;
        }
        &__name {
          display: inline-block;
          padding: 0.444em 0.889em;
          margin-bottom: 0.722em;
          border-radius: 0.889em;
          border-bottom-right-radius: 0;
          font-size: 18px;
          font-weight: 700;
          background: $darkgreen;
          color: $yellow;
        }
        &__message {
          min-height: 10em;
          font-family: 'Noto-Ver';
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: -0.04em;
          color: $white;
          word-break: break-all;
        }
        &__thumbsup {
          margin-top: auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .count {
            display: flex;
            align-items: center;
            font-family: 'Verdana Pro';
            font-weight: 700;
            font-size: 16px;
            color: $white;
            letter-spacing: 0;
            span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-right: 0.500em;
              width: 1.500em;
              height: 1.500em;
              background: #ab042e;
              border-radius: 999px;
              .icon {
                width: 0.688em;
                fill: currentColor;
                color: $white;
              }
            }
          }
          button {
            width: 50px;
            height: 50px;
            border-radius: 999px;
            background: $white;
            box-shadow: 0px 4px 11.16px 0.84px rgba(182, 182, 182, 0.2);
            .icon {
              width: 23px;
              fill: currentColor;
              color: #d5d5d5; 
            } 
            &.active {
              background: $yellow;
              .icon {
                color: $red;
              }
            }
          }
        }
        .icon-tail {
          position: absolute;
          top: 100%;
          right: 40px;
          width: 30px;
          fill: currentColor;
        }
        &:nth-child(6n+1),
        &:nth-child(6n+5) {
          background: $red; 
          .icon-tail { 
            color: $red;
          } 
        }
        &:nth-child(6n+2),
        &:nth-child(6n+6)  {
          background: $lightyellow; 
          .daenamu__name {
            background: $yellow;
            color: $darkgreen; 
          }
          .daenamu__message {
            color: $black;
          }
          .daenamu__thumbsup {
            .count {
              color: $black;
              span {
                background: #f6c18d;
              }
            }
          }
          .icon-tail { 
            color: $lightyellow;
          } 
        }
        &:nth-child(6n+3),
        &:nth-child(6n+4)  {
          background: $darkgreen; 
          .daenamu__name {
            background: $red;
            color: $yellow; 
          }
          .daenamu__thumbsup {
            .count {
              span {
                background: #057644;
              }
            }
          }
          .icon-tail { 
            color: $darkgreen;
          } 
        }   
      }
      &.pc {display: grid;}
      &.mo {display: none;}
    }
  }
  .event-more {
    position: absolute;
    left: 0;
    right: 0; 
    bottom: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 205px;
    background: linear-gradient(0, rgba(255,200,50,1) 0%, rgba(255,200,50,0) 80%);;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3.500em;
      width: 10.000em;
      height: 3.000em;
      border-radius: 999px;
      background: $white;
      font-weight: 900;
      font-size: 20px;
      color: $red;
      box-shadow: 0px 4px 11.16px 0.84px rgba(134, 109, 39, 0.2);
      img {
        margin-right: 0.400em;
      }
    }
  }
  .event-subscribe {
    position: relative;
    background: $yellow;
    &.more-area {
      margin-top: -210px;
    }
    &-round {
      background-color: $darkgreen;
      background-image: url('../assets/images/newsletter/bg_pattern_l.png'), url('../assets/images/newsletter/bg_pattern_r.png');
      background-position: 60px 95px, calc(100% - 60px) 95px;
      background-size: auto;
      border-top-left-radius: 200px;
      border-top-right-radius: 200px; 
    }
    .wrapper { 
      padding-top: 82px;
      padding-bottom: 82px;
    }
    &__contents {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 60px;
      .event-contents__title {
        .label {
          background: $yellow;
          color: $red;
        }
      } 
      .subscribe-form {
        width: 100%;
        max-width: 724px;
        padding: 2.500em;
        padding-bottom: 2em;
        background: #ffffff33;
        border-radius: 1.875em;
        font-size: 16px; 
        &__input {
          border-radius: 12px; 
          .v-input__slot {
            padding: 10px 24px !important; 
          }
          input { 
            font-weight: 500;
            font-size: 16px;
            font-family: 'Noto-Ver';
          }
          input::placeholder {  
            letter-spacing: -0.05em;
            color: #bbbbbb;
          }
          &:not(:last-of-type) {
            margin-bottom: 8px;
          } 
          &.error--text { 
            box-shadow: 0px 0px 0px 2px $red;
          }
        }
        &__button {
          width: 132px; 
          white-space: normal;
          border-radius: 12px;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.3;
          color: $white;
          .v-btn__content { 
            flex: auto;
          }
          &.v-btn--disabled.v-btn--has-bg {
            background-color: #e61e50aa !important;
            color: #ffffffaa !important;
          }
          &-pc { display: block; }
          &-mo { display: none; }
        }
        &__checkbox {
          margin-top: 0;
          padding-top: 0;
          margin-right: 8px;
          &.v-input--is-label-active {
            .v-input--selection-controls__input {
              background: $red !important; 
            } 
          }
          .v-input--selection-controls__input {
            background: #ffffff;
            border-radius: 4px;
            margin-right: 8px;
          }
          .v-icon {
            font-size: 20px;
            color: #d5d5d5;
            &.error--text {
              color: #d5d5d5 !important;
              caret-color: #d5d5d5 !important;
            }
          } 
          .v-label { 
            color: $white;
            &.error--text {
              color: $white !important;
            }
          }
          &.error--text { 
            .v-input--selection-controls__input {
              border: 2px solid $red;
            }
          }
        }
        .input-area { 
          display: flex; 
          gap: 12px;
          margin-bottom: 20px;
          &__text {
            flex-grow: 1; 
          }
        }
        .checkbox-area { 
          &__checkbox {
            width: 100%;
            display: flex;
            align-items: center; 
            justify-content: space-between;
            margin-bottom: 8px;
            color: $white;
            font-weight: 700;
            font-size: 16px;  
            button {
              display: flex;
              align-items: center;
              font-size: 14px;
              letter-spacing: -0.05em;
              img {
                margin-left: 7px;
                transform: rotate(180deg);
              }
            } 
            &.active {
              button img {
                transform: rotate(0);
              }
            }
          } 
          &__desc {
            margin-top: 8px;
            margin-left: 32px;
            padding: 16px;
            background: #ffffff66;
            border-radius: 8px;
            font-size: 14px;
            color: $black;
            line-height: 1.3;
            a {
              font-weight: 700;
              color: #0f69af;
              text-decoration: underline;
            }
            &:not(:last-of-type) {
              margin-bottom: 12px;
            }
          }
        }
        &__complete {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 192px; 
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          color: $white;
          line-height: 1.5;
          br {display: none;}
        }
      } 
    }  
    &__banners {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 40px;
      .banner {
        display: flex;
        flex-direction: column;
        &__title {
          margin-bottom: 0.400em;
          font-size: 20px;
          font-weight: 700;
          color: $yellow;
        }
        &__text {
          margin-bottom: 0.750em;
          font-size: 16px;
          font-weight: 500;
          color: $white;
          line-height: 1.4;
        }
        &__img {
          display: block;
          margin-top: auto;
          cursor: pointer;
          img {
            display: block;
          }
        } 
      }
    }
  }
  .event-contents { 
    &__title {
      .label {
        display: inline-block;
        position: relative;
        padding: 0.600em 1.200em;
        margin-bottom: 2.000em;
        background: $red;
        border-radius: 999px;
        color: $white;
        font-size: 20px;
        font-weight: 700;
        &::after {
          content: '';
          position: absolute;
          bottom: calc(100% - 0.300em);
          right: 0;
          width: 2.600em;
          height: 2.000em;
          background-image: url('../assets/images/info/ic_santa_hat.png');
          background-position: bottom center;
          background-size: contain;
        }
      }
      h3 {
        font-size: 48px;
        font-weight: normal;
        color: $white;
        line-height: 1.4;
        span {
          font-weight: 900;
        }
      }
    }
  }
}
@media (max-width: 1920px) {
  .event-page {
    .btn-share {
      &.floating {
        right: calc(50% - 47.39vw);
      }
    }
  }
}
@media (max-width: 1400px) { 
  .event-page {
    .btn-share { 
      &.floating {
        font-size: 15px; 
      font-size: 15px; 
        font-size: 15px; 
      }
    }
    .event-main {
      &-round { 
        border-bottom-left-radius: 120px;
        border-bottom-right-radius: 120px; 
      }
      &__title { 
        .sub { 
          font-size: 24px; 
        }
        h2 { 
          font-size: 64px; 
        }
        p {
          font-size: 18px; 
        } 
      }
      &__contents {
        .chat {
          img { 
            max-width: 360px; 
          } 
          &:nth-child(1) { 
            margin-bottom: 16px;
          }
          &:nth-child(2) { 
            margin-bottom: 16px;
          } 
        }
      } 
    }
    .event-info {
      background-image: url('../assets/images/info/bg_gift_01.png'), url('../assets/images/info/bg_gift_02.png'); 
      background-position: left calc(100% + 56px), calc(50% - 370px) calc(50% + 175px);
      background-size: 200px auto, 230px auto; 
      &__contents {
        .info {
          max-width: 680px;
          font-size: 12px;
          &__title {
            font-size: 24px;
          }
          &__lists {
            li {
              font-size: 16px;
            } 
          }
        }
        .gift {
          max-width: 680px; 
        }
      } 
    } 
    .event-list {
      &__filter {
        button {
          font-size: 14px;
        }
      }
      &__contents {
        column-gap: 16px;
        row-gap: 36px; 
        padding-bottom: 72px;
        .daenamu {
          border-radius: 36px;
          .inner {  
            padding: 16px;
            border-radius: 30px; 
            background-position: calc(100% - 18px) top ;
            background-size: 34px auto;
          }
          &__name { 
            font-size: 16px; 
          }
          &__message { 
            font-size: 14px; 
          }
          &__thumbsup { 
            .count { 
              font-size: 14px; 
            }
            button {
              width: 48px;
              height: 48px; 
              .icon {
                width: 20px; 
              }  
            }
          }
          .icon-tail { 
            right: 36px;
            width: 28px; 
          } 
        } 
      } 
    }
    .event-more {
      height: 180px;
      button {
        font-size: 18px;
      } 
    }
    .event-subscribe {
      &.more-area {
        margin-top: -170px;
      }
      &-round { 
        background-position: 40px 95px, calc(100% - 40px) 95px; 
        border-top-left-radius: 180px;
        border-top-right-radius: 180px; 
      }
      &__contents {
        margin-bottom: 56px;
        .subscribe-form { 
          max-width: 680px; 
          font-size: 12px;
          &__complete {
            font-size: 22px;
          }
        } 
      }
      &__banners {
        gap: 36px;
        .banner {
          &__title {
            font-size: 18px;
          }
          &__text {
            font-size: 14px; 
          }
        }
      } 
    }
 
    .event-contents { 
      &__title {
        .label { 
          font-size: 16px; 
        }
        h3 {
          font-size: 44px; 
        }
      }
    }
  } 
}
@media (max-width: 1200px) {
  .event-page {
    .event-main {
      &__contents {
        .chat { 
          &:nth-child(1) { 
            margin-left: 60px;  
          }
          &:nth-child(2) {
            margin-right: 60px;  
          }
          &:nth-child(3) { 
            margin-left: 60px;   
          }  
        }
      }
    }
    .event-info {
      background-image: url('../assets/images/info/bg_gift_01.png'), url('../assets/images/info/bg_gift_03.png');
      background-size: 180px auto, 122px auto;
      background-position: left calc(100% + 56px), right 80px;
      .wrapper { 
        flex-direction: column;
        align-items: center;
      } 
    }
    .event-list {
      &__contents { 
        grid-template-columns: repeat(2, minmax(0, 1fr));
      } 
    }
    .event-subscribe {
      &__contents {
        flex-direction: column;
        align-items: center; 
      }
      &__banners { 
        grid-template-columns: repeat(1, minmax(0, 1fr));
        justify-items: center;
      }
    } 
    .event-contents {
      &__title {
        text-align: center;
        margin-bottom: 60px; 
      } 
    }
  } 
}
@media (max-width: 900px) {
  .event-page {
    .event-main {
      &__contents {
        .chat { 
          img {
            max-width: 300px; 
          }
          &:nth-child(1) { 
            margin-left: 40px;  
          }
          &:nth-child(2) {
            margin-right: 40px;  
          }
          &:nth-child(3) { 
            margin-left: 40px;   
          }  
        } 
      }
    }
  }
}
@media (max-width: 800px) {
  .event-page {
    .btn-floating-daenamu {
      display: block;
    }
    .btn-share { 
      padding: 1.000em 1.154em; 
      font-size: 26px;
      box-shadow: 0px 4px 11.16px 0.84px rgba(75, 75, 75, 0.2); 
      .icon-share { 
        margin-right: 0.385em;
        width: 0.769em;
        height: 1.000em; 
      } 
      &.floating {
        top: 30px;
        right: calc(50% - 46.66vw); 
        font-size: 26px;
      }
    } 
    .event-main { 
      &-round { 
        background-image: url('../assets/images/main/bg_m.png'); 
        border-bottom-left-radius: 80px;
        border-bottom-right-radius: 80px; 
      }
      .wrapper {
        display: block; 
        padding-top: 76px;
        padding-bottom: 78px;
      }
      &__title {
        text-align: center; 
        margin-bottom: 60px;
        .sub { 
          margin-bottom: 0.375em;
          font-size: 32px; 
        }
        h2 {
          margin-bottom: 0.344em;
          font-size: 64px; 
          br {display: none;}
        }
        p {
          font-size: 26px; 
          img {
            width: 0.923em; 
            margin-left: 0.231em;
          }
        }
      }
      &__contents {
        .chat {
          img {
            display: inline-block;
            max-width: 460px;
            &.pc {display: none;}
            &.mo {display: inline-block;}
          } 
          &:nth-child(1) { 
            margin-left: 0; 
            margin-bottom: 20px;
          }
          &:nth-child(2) {
            margin-right: 0; 
            text-align: right;
            margin-left: 0; 
            margin-bottom: 0;
          }
          &:nth-child(3) {display: none;}    
        }
      }
    }
    .event-info { 
      background-image: url('../assets/images/info/bg_gift_04.png');
      background-position: 27px 110px;
      background-size: 108px auto;
      .wrapper { 
        display: block;
        padding-top: 112px;
        padding-bottom: 70px;
      }
      &__contents {
        .info { 
          position: relative;
          padding: 40px;
          margin-bottom: 60px; 
          border-radius: 30px; 
          .bg-info-gift {
            display: block;
            position: absolute;
            bottom: calc(100% - 50px);
            left: calc(100% - 85px);
          }
          &__title {
            margin-bottom: 0.750em;
            padding-bottom: 0.750em; 
            font-size: 32px; 
          }
          &__lists {
            li { 
              font-size: 26px; 
              .num {
                margin-right: 0.769em;
              }  
              img {
                width: 1.154em;
              }
              &:not(:last-child) {
                margin-bottom: 0.769em;
              }
            } 
            br { display: block; }
          }
        }
        .gift {
          .pc {display: none;}
          .mo {display: inline-block;}
        }
      }
    }
    .event-form {
      .wrapper {
        padding-top: 80px;
        padding-bottom: 80px;
      }
      &__contents { 
        margin-bottom: 40px;
        .input-nickname { 
          max-width: 480px;
          margin-bottom: 32px;
          &__label { 
            margin-bottom: 0.273em;
            padding: 0.455em 0.818em;  
            font-size: 22px; 
          }
          &__input { 
            padding: 6px 0; 
            border-bottom: 2px solid #ffe293;
            span {
              font-size: 30px; 
            }
            button {
              padding: 0.636em 0.955em; 
              border-radius: 0.545em;
              font-size: 22px;  
            } 
          }
        }
        .input-form { 
          &__input {
            width: 100%; 
            border-radius: 12px;
            .v-input__slot {
              padding: 0.923em 0.769em !important; 
              font-size: 26px !important; 
            }
            input {  
              font-size: 26px; 
            } 
          }
          &__textarea {
            position: relative;
            border-radius: 16px;
            .v-input__slot {
              padding: 0.923em !important; 
              font-size: 26px; 
            }
            .v-text-field__details {
              position: absolute;
              right: 12px;
              bottom: 10px; 
              .v-counter {
                font-size: 1em;
              }
            }
            textarea { 
              min-height: 190px;
              font-size: 26px; 
            }
          }
          &__button {
            margin-top: 0.769em;
            border-radius: 0.615em;
            padding: 1.077em !important; 
            font-size: 32px;  
          } 
          &__phone { 
            text-align: right; 
            margin-bottom: 20px; 
            .text {
              display: block;
              position: static; 
              transform: translateY(0);  
              padding-top: 0.600em;
              padding-right: 0;
              font-size: 20px; 
            }
          }
          &__message { 
            flex-direction: column;
            border-radius: 0;
            overflow: visible;
          }
        }
      }
      &__share {
        .text-share {
          flex-direction: column;
          margin-right: 32px;
          gap: 0.8em;
          p {
            font-size: 30px;
          }
        }
      }
    }
    .event-list { 
      &__filter { 
        margin-bottom: 32px;
        button {  
          padding: 1.000em 1.500em 1.000em 1.167em;
          font-size: 24px;
          .icon {
            margin-right: 0.417em;
            width: 1em;
          } 
          &:hover {
            background: $red;
          }
        }
      }
      &__contents {
        display: block;
        grid-template-columns: none;
        column-gap: 0;
        row-gap: 0;
        margin: 0 -40px;
        padding-bottom: 70px;
        .daenamu { 
          padding: 12px; 
          border-radius: 40px;
          .inner {  
            width: auto;
            padding: 30px;
            border-radius: 34px;  
            background-position: calc(100% - 28px) top;
            background-size: 54px auto;
          }
          &__name { 
            padding: 0.462em 0.923em;
            padding-right: 1.077em;
            margin-bottom: 0.769em;
            border-radius: 0.923em;
            border-bottom-right-radius: 0;
            font-size: 26px; 
          }
          &__message {
            min-height: 10.417em; 
            font-size: 24px;
          }
          &__thumbsup {
            margin-top: auto; 
            .count {  
              font-size: 24px; 
              span {  
                margin-right: 0.500em;
                width: 1.500em;
                height: 1.500em; 
                .icon {
                  width: 0.708em; 
                }
              }
            }
            button {
              width: 72px;
              height: 72px; 
              .icon {
                width: 35px; 
              }  
            }
          }
          .icon-tail { 
            right: 60px;
            width: 45px; 
          }
          &:nth-child(6n+1),
          &:nth-child(6n+4) {
            background: $red; 
            .daenamu__name {
              background: $darkgreen;
              color: $yellow;
            }
            .daenamu__message {
              color: $white;
            }
            .daenamu__thumbsup {
              .count {
                color: $white;
                span {
                  background: #ab042e;
                }
              }
            }
            .icon-tail { 
              color: $red;
            }  
          }
          &:nth-child(6n+2),
          &:nth-child(6n+5) {
            background: $lightyellow; 
            .daenamu__name {
              background: $yellow;
              color: $darkgreen; 
            }
            .daenamu__message {
              color: $black;
            }
            .daenamu__thumbsup {
              .count {
                color: $black;
                span {
                  background: #f6c18d;
                }
              }
            }
            .icon-tail { 
              color: $lightyellow;
            }  
          }
          &:nth-child(6n+3),
          &:nth-child(6n+6) {
            background: $darkgreen; 
            .daenamu__name {
              background: $red;
              color: $yellow; 
            }
            .daenamu__message {
              color: $white;
            }
            .daenamu__thumbsup {
              .count {
                color: $white;
                span {
                  background: #057644;
                }
              }
            }
            .icon-tail { 
              color: $darkgreen;
            } 
          }  
        } 
        .swiper-slide {
          width: 80%;
          margin-bottom: 25px;
        }
        &.pc {display: none;}
        &.mo {display: block;}
      }
    }
    .event-more {
      display: none;
    }
    .event-subscribe {
      &.more-area {
        margin-top: 0;
      }
      &-round { 
        background-image: url('../assets/images/newsletter/bg_pattern.png');
        background-position: center 80px; 
        background-repeat: no-repeat;
        border-top-left-radius: 80px;
        border-top-right-radius: 80px; 
      }
      .wrapper {
        padding-top: 112px;
        padding-bottom: 80px; 
      }
      &__contents {  
        margin-bottom: 60px;
        .event-contents__title {
          .label {
            background: $yellow;
            color: $red;
          }
        } 
        .subscribe-form { 
          max-width: 720px;
          padding: 36px; 
          border-radius: 30px; 
          &__input {
            border-radius: 0.462em;
            font-size: 26px; 
            .v-input__slot {
              padding: 0.923em 0.769em !important;   
            }
            input {  
              font-size: 26px; 
              padding: 0;
            } 
            &:not(:last-of-type) {
              margin-bottom: 20px;
            }
          }
          &__button {
            margin-top: 5vw;
            width: 100%;
            height: auto !important;
            padding: 0.875em 0.938em !important; 
            border-radius: 0.500em;
            font-size: 32px;  
            &-pc { display: none; }
            &-mo { display: block; }
          }
          &__checkbox {  
            .v-input--selection-controls__input { 
              border-radius: 0.313em;
              margin-right: 0.375em;
              width: 1.250em;
              height: 1.250em;
              font-size: 32px; 
            }
            .v-icon {
              font-size: 32px; 
            }  
            .v-label { 
              font-size: 3vw;
            }
          }
          .input-area { 
            margin-bottom: 32px;
          }
          .checkbox-area { 
            &__checkbox { 
              margin-bottom: 0.667em;
              font-size: 3vw;  
              button {
                font-size: 2.75vw;
                img {
                  margin-left: 0.455em;
                  width: 0.636em;
                }
              }
            }
            &__desc {
              margin-top: 0.727em;
              margin-left: 0;
              padding: 0.909em;
              border-radius: 0.727em;
              font-size: 2.75vw;
              &:not(:last-of-type) {
                margin-bottom: 0.909em;
              }
            }
          }
          &__complete { 
            min-height: 12.500em; 
            font-size: 32px; 
            br {display: block;}
          }
        } 
      } 
      &__banners {  
        gap: 50px;
        .banner {
          width: 100%;
          &__title {
            margin-bottom: 0.385em;
            font-size: 26px; 
          }
          &__text {
            margin-bottom: 0.727em;
            font-size: 22px; 
          }
          &__img { 
            img {
              width: 100%; 
            }
          } 
        }
      }  
    }
    .event-contents { 
      &__title {
        margin-bottom: 60px; 
        .label {  
          padding: 0.462em 1.077em;
          margin-bottom: 1.077em; 
          font-size: 26px; 
          &::after {   
            width: 2.308em;
            height: 1.462em; 
          }
        }
        h3 {
          font-size: 48px; 
          line-height: 1.25; 
        }
      }
    }
  } 
}
@media (max-width: 700px) {
  .event-page {
    .btn-floating-daenamu { 
      bottom: 8vw;
      button {
        font-size: 4.571vw; 
      }
    }  
    .btn-share {
        font-size: 3.6vw;
      &.floating {
        font-size: 3.6vw;
        top: 4.285vw;
      }
    }
    .event-main {
      &-round {  
        background-size: contain;
        border-bottom-left-radius: 11.428vw;
        border-bottom-right-radius: 11.428vw; 
      } 
      .wrapper { 
        padding-top: 10.857vw;
        padding-bottom: 11.142vw;
      }
      &__title {
        margin-bottom: 8.571vw;
        .sub {  
          font-size: 4.571vw; 
        }
        h2 { 
          font-size: 9.142vw;  
        }
        p {
          font-size: 3.714vw;  
        }
      }
      &__contents {
        .chat {
          img {
            width: 65.714vw;
          }
          &:nth-child(1) { 
            margin-bottom: 2.857vw;
          }
        }
      }
    }
    .event-info {
      background-position: 3.857vw 15.714vw;
      background-size: 15.428vw auto;
      .wrapper { 
        padding-top: 16vw;
        padding-bottom: 10vw;
      } 
      &__contents {
        .info {
          padding: 5.714vw;
          margin-bottom: 8.571vw; 
          border-radius: 4.285vw; 
          .bg-info-gift {
            bottom: calc(100% - 7.142vw);
            left: calc(100% - 12.142vw); 
            width: 21.714vw;
          }
          &__title {
            font-size: 4.571vw;
          }
          &__lists {
            li {
              font-size: 3.714vw;
            }
          }

        }
      }
    }
    .event-form {
      .wrapper {
        padding-top: 11.428vw;
        padding-bottom: 11.428vw; 
      }
      &__contents {
        margin-bottom: 5.714vw;
        .input-nickname {
          margin-bottom: 4.571vw;
          &__label {  
            font-size: 3.142vw; 
          }
          &__input {
            padding: 0.857vw 0;  
            span {
              font-size: 4.285vw; 
            }
            button { 
              font-size: 3.142vw;  
            }  
          }
        }
        .input-form {
          &__input {  
            border-radius: 1.714vw;
            .v-input__slot { 
              font-size: 3.714vw !important;
            }
            input {  
              font-size: 3.714vw; 
              padding: 0;
            } 
          } 
          &__textarea { 
            border-radius: 2.285vw;
            .v-input__slot { 
              font-size: 3.714vw; 
            }
            .v-text-field__details { 
              right: 1.714vw;
              bottom: 1.428vw; 
              .v-counter {
                font-size: 2.285vw;
              }
            }
            textarea { 
              min-height: 28vw;
              font-size: 3.714vw; 
            }
          }
          &__button {
            font-size: 4.571vw;   
          }
          &__phone {  
            margin-bottom: 2.857vw; 
            .text {  
              font-size: 2.857vw; 
            }
          } 
        } 
      } 
      &__share {
        .text-share { 
          margin-right: 4.571vw; 
          p {
            font-size: 4.285vw;
          }
        }
      }
    }
    .event-list {
      &__filter {
        margin-bottom: 4.571vw;
        button {
          font-size: 3.428vw; 
        }
      }
      &__contents {
        margin: 0 -5.714vw;
        padding-bottom: 10vw;
        .daenamu {
          padding: 1.714vw; 
          border-radius: 5.714vw;
          .inner {
            padding: 4.285vw;
            border-radius: 4.857vw;  
            background-position: calc(100% - 4vw) top;
            background-size: 7.714vw auto; 
          }
          &__name {
            font-size: 3.714vw;
          }
          &__message { 
            font-size: 3.428vw; 
          }
          &__thumbsup {
            .count {
              font-size: 3.428vw;
            }
            button {
              width: 10.285vw;
              height: 10.285vw; 
              .icon {
                width: 5vw; 
              }   
            }
          }
          .icon-tail { 
            right: 8.571vw;
            width: 6.428vw; 
          }
        }
        .swiper-slide { 
          margin-bottom: 3.571vw;
        } 
      }
    }
    .event-subscribe {
      &-round {
        background-position: center 11.428vw; 
        background-size: contain;
        border-top-left-radius: 11.428vw;
        border-top-right-radius: 11.428vw; 
      } 
      .wrapper {
        padding-top: 16vw;
        padding-bottom: 11.428vw; 
      }
      &__contents {
        margin-bottom: 8.571vw;
        .subscribe-form {
          padding: 5.142vw; 
          border-radius: 4.285vw;
          &__form {  
            gap: 5.714vw;
          }
          &__input {
            font-size: 3.714vw; 
            input {  
              font-size: 3.714vw; 
            } 
            &:not(:last-of-type) {
              margin-bottom: 2.857vw;
            }
          } 
          &__button {
            font-size: 4.571vw;   
          }
          &__checkbox {
            margin-right: 2.571vw;
            .v-input--selection-controls__input {  
              font-size: 4.571vw; 
            }
            .v-icon {
              font-size: 4.571vw; 
            }  
          }
          .input-area {  
            margin-bottom: 4.571vw;
          }  
          &__complete {  
            font-size: 4.571;  
          }
        }
      }
      &__banners {
        gap: 7.142vw;
        .banner {
          &__title { 
            font-size: 3.714vw; 
          }
          &__text { 
            font-size: 3.142vw; 
          }
        } 
      }
    }
 
    .event-contents {
      &__title {
        margin-bottom: 8.571vw;
        .label {
          font-size: 3.714vw;
          &::after {   
            bottom: calc(100% - 0.857vw);
            width: 2.308em;
            height: 1.462em; 
          }
        }
        h3 {
          font-size: 6.857vw;
        }
      }

    }
  }
}
 
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
} 
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}   
@keyframes bounce {
  0%, 100% {
    transform: translateY(5px); 
  }
  50% {
    transform: translateY(0); 
  }
} 
</style>