<template> 
  <svg v-if="name === 'kakao'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.07 65.63"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M35.53,0C15.91,0,0,12.57,0,28.07,0,38,6.58,46.8,16.49,51.78L13.14,64.29a1,1,0,0,0,.25,1,1,1,0,0,0,.72.29,1.06,1.06,0,0,0,.63-.21l14.41-9.73a44.47,44.47,0,0,0,6.38.45c19.63,0,35.54-12.56,35.54-28.07S55.16,0,35.53,0ZM17.6,35.51a1.61,1.61,0,0,1-1.7,1.77,1.61,1.61,0,0,1-1.7-1.77V23.16H10.62A1.44,1.44,0,0,1,9.1,21.62a1.51,1.51,0,0,1,1.52-1.55H21.17a1.5,1.5,0,0,1,1.52,1.55,1.44,1.44,0,0,1-1.52,1.54H17.6ZM34,37.28a1.58,1.58,0,0,1-1.66-1.18l-.74-2.32h-7.1l-.76,2.3a1.56,1.56,0,0,1-1.64,1.2,1.6,1.6,0,0,1-1.67-1.57,1.77,1.77,0,0,1,.16-.74L25.56,21.9a2.78,2.78,0,0,1,2.53-2,2.67,2.67,0,0,1,2.53,2l4.78,13a2.85,2.85,0,0,1,.19.88A1.49,1.49,0,0,1,34,37.28Zm11.44-.19H39.14a1.68,1.68,0,0,1-1.78-1.88V21.69a1.7,1.7,0,1,1,3.4,0V34h4.72a1.61,1.61,0,0,1,1.22.47,1.51,1.51,0,0,1,.4,1.14A1.55,1.55,0,0,1,45.48,37.09Zm14.08.19h-.27c-.63-.06-1-.46-1.63-1.34l-4.47-6.09L52,31.08v4.58a1.62,1.62,0,0,1-1.7,1.62,1.57,1.57,0,0,1-1.7-1.62V21.59a1.63,1.63,0,0,1,1.7-1.67A1.59,1.59,0,0,1,52,21.59V27l6-6.24a1.85,1.85,0,0,1,1.35-.62,1.54,1.54,0,0,1,1.62,1.56c0,.47-.28.78-.83,1.39l-.42.47-4.09,4.13,4.84,6.33A2.65,2.65,0,0,1,61.25,36,1.55,1.55,0,0,1,59.56,37.28Z"/><path class="cls-1" d="M28.05,23l-2.56,7.74h5.2L28.12,23A0,0,0,0,0,28.05,23Z"/></g></g></svg>
  <svg v-else-if="name === 'youtube'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.37 35.96"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M50.3,5.62a6.44,6.44,0,0,0-4.54-4.55C41.75,0,25.69,0,25.69,0S9.62,0,5.62,1.07A6.45,6.45,0,0,0,1.07,5.62C0,9.62,0,18,0,18s0,8.36,1.07,12.37a6.44,6.44,0,0,0,4.55,4.54C9.62,36,25.69,36,25.69,36s16.06,0,20.07-1.07a6.43,6.43,0,0,0,4.54-4.54c1.07-4,1.07-12.37,1.07-12.37S51.37,9.62,50.3,5.62ZM20.55,25.69V10.27L33.9,18Z"/></g></g></svg>
  <svg v-else-if="name === 'blog'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.36 30.02"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M7.06,11.08a1.12,1.12,0,1,0,1.12,1.11A1.11,1.11,0,0,0,7.06,11.08Z"/><circle class="cls-1" cx="18.07" cy="12.16" r="1.2"/><path class="cls-1" d="M28,0H4.33A4.33,4.33,0,0,0,0,4.33V18.4a4.33,4.33,0,0,0,4.33,4.33H12.7l2.85,6.71s.2.58.67.58h0c.47,0,.67-.58.67-.58l2.85-6.71H28a4.33,4.33,0,0,0,4.33-4.33V4.33A4.33,4.33,0,0,0,28,0ZM10.17,12.44A2.68,2.68,0,0,1,7.5,15.08a2.22,2.22,0,0,1-1.61-.68v.46H4v-8s0,0,0,0H4c.12,0,1.94,0,1.94,0V9.93c.45-.78,1.77-.77,1.77-.77C10.53,9.43,10.17,12.44,10.17,12.44Zm3.41-3v5.47h-1.9V9.46c0-.73-.91-.94-.91-.94V6.61A2.63,2.63,0,0,1,13.58,9.41Zm4.49,5.68a3.07,3.07,0,0,1-3.18-2.93,3.06,3.06,0,0,1,3.18-2.92,3.06,3.06,0,0,1,3.18,2.92A3.07,3.07,0,0,1,18.07,15.09Zm10.38,0a2.69,2.69,0,0,1-2.79,2.77H24.8v-1.8h.52s1.18.11,1.17-1.71c0,0-.23.74-1.9.74a2.5,2.5,0,0,1-2.35-2.48v-.87a2.69,2.69,0,0,1,2.64-2.58,1.93,1.93,0,0,1,1.65.74V9.31h1.92Z"/><circle class="cls-1" cx="25.44" cy="12.16" r="1.17"/></g></g></svg>
  <svg v-else-if="name === 'open-check'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M5.5,8.06l2.89,3.76,8.24-8.25" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px"/><path d="M17,9.14a8,8,0,1,1-4.76-7.45" style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px"/></g></g></svg> 
  <svg v-else-if="name === 'share'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.98 25.94"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><circle cx="15.99" cy="3.99" r="3.99"/><circle cx="3.99" cy="12.97" r="3.99"/><circle cx="15.99" cy="21.95" r="3.99"/><polyline points="15.99 21.95 3.99 12.97 15.99 3.99" style="fill:none;stroke-miterlimit:10"/></g></g></svg>
  <svg v-else-if="name === 'filter'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.76 87.78"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M49.38,68.07l48-58.37A5.93,5.93,0,0,0,92.81,0H5.94A5.93,5.93,0,0,0,1.36,9.7Z"/><path d="M60.22,79.68l-18.73,7.8a3.85,3.85,0,0,1-5.33-3.56V43.13H62.6v33A3.85,3.85,0,0,1,60.22,79.68Z"/></g></g></svg>
  <svg v-else-if="name === 'thumbsup'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.55 19.97"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect y="7.86" width="4.06" height="12.12" /><path d="M6.16,7.88V18.33A1.63,1.63,0,0,0,7.8,20H17a1.54,1.54,0,0,0,1.38-.87l4-8.25a2.62,2.62,0,0,0-2.35-3.77H13.21l1.34-4.73A2,2,0,0,0,14,.41L13.91.28a1,1,0,0,0-1.37,0L6.71,6.49A2,2,0,0,0,6.16,7.88Z" /></g></g></svg>
  <svg v-else-if="name === 'tail'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 16" style="enable-background:new 0 0 30 16;" xml:space="preserve"> <path d="M0,0c0,0,5.7,16.4,30,16c0,0-12.3-5.1-10.9-16H0z"/> </svg>
</template>

<script>
export default {
  props: {
    name: String,
  },
};
</script> 